.generic-page-error {

  position: relative;
  border-radius: 4px;
  background-color: white;
  border:1px solid #666;
  padding: 15px 35px 15px 55px;
  font-family: $avenir-next-rounded-regular;
  font-family: verdana;
  font-size: 1em;
  border: 1px solid red;
  //margin: 15px 0;
  margin: 5px 0 15px 0; //design override
  line-height: 135%;
  display: none;

  &:before {
    content:"";
    background-image: url(/images/icons/error/error-red-triangle.png);
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    height: 100%;
    width: 30px;
    display: block;
    top: 0;
    left: 15px;
  }

  .error-description {
    color: red;
  }

  .error-code {
    color: #666;
  }

  .close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 16px;
    height: 16px;
    background-image: url(/images/global/clear.png);
    background-repeat: no-repeat;
    background-position: center center;
  }
}