/*geolocation*/
.geo-overlay {
	.geo-background {
		background-color: rgba(0, 0, 0, 0.8);
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		position: fixed;
		z-index: 11;

		.geo-wrapper {
			background-color: #fff;
			border-radius: 10px;
			max-width: 300px;
			padding: 10px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			max-height: 230px;
			width: 100%;
			
			@include respond-to(sm) {
				max-width: 350px;
				padding: 20px;
			}
			@include respond-to(md) {
				max-width: 400px;
				padding: 20px;
				min-height: 250px;
			}
		}
		.geo-close {
			background-image: url(/images/global/geolocation/close-icon-geo.png);
			background-repeat: no-repeat;
			background-size: 13px;
			width: 13px;
			height: 13px;
			float: right;
			cursor: pointer;
			margin-bottom: 13px;
			-webkit-transition: ease 0.25s all;
			transition: ease 0.25s all;

			&:hover {
				-ms-transform: rotate(180deg); /* IE 9 */
				-webkit-transform: rotate(180deg); /* Safari */
				transform: rotate(180deg);
			}
		}
		.geo-title {
			clear: both;
			text-align: center;
		}
		.hr-center {
			margin: 30px auto;
		}
		.form-group {
			position: relative;
			max-width: 237px;
			margin: 0 auto;

			@include respond-to(sm) {
				max-width: 270px;
			}
			@include respond-to(md) {
				max-width: 300px;
			}
			.search-box {
				font-size: 16px;
				border: none;
				height: 32px;
				margin-bottom: 10px;
				padding: 0 0 0 15px;
			}
			.btn {
				width: 100%;
				padding: 0;
				height: 28px;
				margin-bottom: 10px;
			}
			.btn-icon span {
				background-image: url(/images/global/geolocation/geo-icon.png);
				width: 14.5px;
				height: 14.5px;
				background-size: cover;
				top: 3px;
			}
		}
		.geo-go-search {
			position: absolute;
			display: block;
			top: 4px;
			right: 3px;
			width: 24px;
			height: 24px;
			background-image: url(/images/global/geolocation/go-search-geo.png);
			background-size: cover;
			cursor: pointer;
			margin-bottom: 10px;
		}
	}
}

/*geolocation*/