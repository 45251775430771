// ----------------------
// FONT DECLARATIONS
//// ---------

$font-stack: Arial, sans-serif;

$avenir-next-bold: 'AvenirNextBold', $font-stack;
$avenir-next-demi: 'AvenirNextDemi', $font-stack;
$avenir-next-regular: 'AvenirNextRegular', $font-stack;
$avenir-next-rounded-demi: 'AvenirNextRoundedDemi', $font-stack;
$avenir-next-rounded-medium: 'AvenirNextRoundedMedium', $font-stack;
$avenir-next-rounded-regular: 'AvenirNextRoundedRegular', $font-stack;
$avenir-next-light: 'AvenirNextLight', $font-stack;
$avenir-next-medium: 'AvenirNextMedium', $font-stack;
$avenir-next-heavy: 'AvenirNextHeavy', $font-stack;

$futura-extra-bold: 'FuturaNextCondensedExtraBold', $font-stack;

.avenir-next-bold {font-family: $avenir-next-bold};
.avenir-next-demi {font-family: $avenir-next-demi};
.avenir-next-regular {font-family: $avenir-next-regular};
.avenir-next-rounded-demi {font-family: $avenir-next-rounded-demi};
.avenir-next-rounded-medium {font-family: $avenir-next-rounded-medium};
.avenir-next-rounded-regular {font-family: $avenir-next-rounded-regular};
.avenir-next-light {font-family: $avenir-next-light};


// HD
// This mixin will set font-family size and color
@mixin font-family-size-color($family, $size, $color) {
    font-family: $family !important;
    font-size: $size !important;
    color: $color !important;
} 

@mixin font-face($font-name, $path, $ttf, $woff2, $woff, $svg, $eot) {
    // Woff + Woff2 are now supported on mayor browsers including IE9 +
    @font-face {
      font-family: $font-name;
      src: url($path+$woff2) format('woff2'),
      url($path+$woff) format('woff');
    }
}
