/*Footer*/
footer.footer {
	background-color: #666766;
	min-height: 275px;
	padding-top: 50px;
	text-align: center;

	.privacy-option {
		width: 25px;
	}

	@include respond-to(xs) {
		padding: 20px;
	}
	li {
		float: left;
		padding: 0px !important;
		margin-bottom: 7px;
	}
	li:nth-last-child(1) {
		padding-right: 0px;
	}
	li>a {
		font-family: $avenir-next-rounded-demi;
		color: #fff;
		text-decoration: underline;
		display: block;
		height: 100%;

		&:hover {
			color: #fff;
		}
	}
	.footer-links {
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.footer-links li>a {
		font-size: $footer-font-size-m;

		@include respond-to(sm) {
			font-size: $footer-font-size-sm
		}
	}
	.container-fluid {
		max-width: 300px; 
		@include respond-to(sm) {
			max-width: 850px
		}
	}
	.footer-copyright {
		margin-top: 15px;
		font-family: $avenir-next-rounded-regular;
		color: #fff;
		font-size: $footer-font-size-m;

		a {
			text-decoration: underline;
			color: #fff;
		}
		div {
			padding: 0;
		}
	}
	.footer-social-icons {
		width: 108px;
		margin: 0 auto 10px;

		li {
			height: 19px;
			width: 19px;
			background-size: cover;

			@include respond-to(sm) {
				width: 37px;
				height: 37px;
			}

			a:focus {
				outline: -webkit-focus-ring-color auto !important;
				outline-offset: 0.3rem;
			}
		}
		@include respond-to(sm) {
			width: 180px;
			margin-bottom: 30px;
			text-align: center;
		}
		@include respond-to(md) {
			margin-bottom: 30px;
		}
		.footer-facebook {
			background-image: url(/images/global/social-icons-mobile.png);
			background-position: 0 0;
			margin-right: 10px;

			@include respond-to(md) {
				background-image: url(/images/global/social-icons.png);
				width: 37px;
				height: 37px;
			}
		}
		.footer-twitter {
			background-image: url(/images/global/social-icons-mobile.png);
			background-position: -29px 0;
			margin-right: 10px;
			@include respond-to(xs) {
				margin-right: 9px;
				width: 20px;
			}

			@include respond-to(sm) {
				background-position: -57px 0;
			}
			@include respond-to(md) {
				background-image: url(/images/global/social-icons.png);
				width: 37px;
				height: 37px;
				background-position: -57px 0;
			}
		}
		.footer-instagram {
			background-image: url(/images/global/social-icons-mobile.png);
			background-position: -58px 0;
			margin-right: 10px;
			@include respond-to(xs) {
				margin-right: 9px;
				width: 20px;
			}

			@include respond-to(sm) {
				background-position: -114px 0;
			}
			@include respond-to(md) {
				background-image: url(/images/global/social-icons.png);
				width: 37px;
				height: 37px;
				background-position: -114px 0;
			}
		}
		.footer-youtube {
			background-image: url(/images/global/social-icons-mobile.png);
			background-position: -88px 0;

			@include respond-to(sm) {
				background-position: -171px 0;
			}
			@include respond-to(md) {
				background-image: url(/images/global/social-icons.png);
				width: 37px;
				height: 37px;
				background-position: -171px 0;
			}
		}
	}
}

/* Footer END*/