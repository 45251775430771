// headers

	// XS - mobile
	$font-size-m-h1:28px;
	$font-size-m-h2:24px;
	$font-size-m-h3:18px;
	$font-size-m-h4:16px;
	$font-size-m-h5:14px;
	$font-size-m-h6:12px;

	// SM - tabletsm
	$font-size-sm-h1:40px;
	$font-size-sm-h2:30px;
	$font-size-sm-h3:20px;

	$font-size-sm-h6:18px;


	// MD - tabletsm
	$font-size-md-h1:40px;
	$font-size-md-h2:30px;


	// LG - desktop
	$font-size-h1:60px;
	$font-size-h2:40px;
	$font-size-h3:24px;
	$font-size-h4:18px;
	$font-size-h5:16px;
	$font-size-h6:11px;	
// headers END


// copy 
	// desktop
	$font-size-copy: 18px;
	$leading: 24px; //line-height

	//SM - tablet
	$font-size-sm-copy: 20px;
	$leading-sm: 24px; //line-height

	//XS - mobile
	$font-size-m-copy: 12px;
	$leading-m: 17px; //line-height
// copy END


// Buttons
	// desktop
	$font-size-btn: 16px;

	//SM - MOBILE
	$font-size-sm-btn: 16px;	

	//XS - MOBILE
	$font-size-m-btn: 14px;	
// Buttons  END


// form 
		// desktop
		$font-size-form: 16px;
		// XS - MOBILE
		$font-size-m-form: 12px;		
// form  END

// nav_bar
	
	// sidebar XS - MOBILE
	$font-size-m-sidebar-primary: 16px;
	// sidebar XS - MOBILE
	$font-size-m-sidebar-secondary: 14px;	
// nav_bar END


//footer

	//XS - MOBILE	
	$footer-font-size-m: 12px;
	//tablet	
	$footer-font-size-sm: 16px;

//footer END