/*copy/Form fields*/
@mixin copy() {
	font-family: $avenir-next-regular;
	font-size: $font-size-m-copy;
	line-height: $leading-m;

	@include respond-to(sm) {
		font-size: $font-size-sm-copy;
		line-height: $leading-sm;
	}
	@include respond-to(md) {
		font-size: $font-size-copy;
		line-height: $leading;
	}
}
.copy {
	@include copy();
}
a:focus, a:hover {
	text-decoration: none;
}

/*copy/Form fields END*/