/*hr-line*/
.hr {
	width: 42.5px;
	margin: 10px 0;
	height: 2px;
	background-color: #000;
	opacity: 0.2;

	@include respond-to(sm) {
		width: 85px;
		margin: 20px 0;
	}
	@include respond-to(md) {
		width: 82px;
		margin: 20px 0;
	}
}
.hr-center {
	width: 42.5px;
	margin: 10px auto;
	height: 2px;
	background-color: #000;
	opacity: 0.2;

	@include respond-to(sm) {
		width: 85px;
		margin: 20px 0;
	}
	@include respond-to(md) {
		width: 82px;
		margin: 20px auto;
	}
}
.hr-full {
	width: 100%;
	height: 2px;
	background-color: #000;
	opacity: 0.2;
	margin: 20px 0;
}

/*hr-line END*/