.disclaimers {
    background-color: #00324f;
    bottom: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    left: 4%;
    margin: 0 auto;
    overflow: hidden;
    padding: 20px;
    position: fixed;
    width: 92%;
    z-index: 3;
    -webkit-transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
    -webkit-transform: translate(0, 80px);
    -moz-transform: translate(0, 80px);
    -o-transform: translate(0, 80px);
    -ms-transform: translate(0, 80px);
    transform: translate(0, 80px);
    display: none;
    transition: all 0.5s ease-in-out;

    &.active {
        opacity: 1;
        -webkit-transform: translate(0, 0px);
        -moz-transform: translate(0, 0px);
        -o-transform: translate(0, 0px);
        -ms-transform: translate(0, 0px);
        transform: translate(0, 0px);
        display: block;
    }
    .disclaimers-content {
        color: #fff;
        text-align: center;
    }
    .disclaimers-close {
        background: transparent url(/images/global/close-icon.png) no-repeat;
        background-size: 12.5px;
        border: 0 none;
        width: 12.5px;
        height: 12.5px;
        float: right;
        right: 10px;
        position: absolute;
        cursor: pointer;
        top: 10px;
        -webkit-transition: background-color 0.25s ease, color 0.25s ease, transform 0.25s ease;
        transition: background-color 0.25s ease, color 0.25s ease, transform 0.25s ease;
        z-index: 10;

        &:hover {
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transform: rotate(180deg); /* Safari */
            transform: rotate(180deg);
        }

        &:focus {
            outline: -webkit-focus-ring-color auto !important;
            outline-offset: 0.3rem !important;
        }

        @include respond-to(sm) {
        }
    }
}
.disclaimer-callout {
    background: transparent none;
    border: 0 none;
    color: color(honda-blue);
    cursor: pointer;
    padding: 0;
    color:#00446b;

    &:focus {
        outline: -webkit-focus-ring-color auto !important;
        outline-offset: .4rem;
    }
}