/*Headers*/
h1 {
	font-size: $font-size-m-h1;
	font-family: $avenir-next-rounded-medium;

	@include respond-to(sm) {
		font-size: $font-size-sm-h1;
	}
	@include respond-to(md) {
		font-size: $font-size-md-h1;
	}
	@include respond-to(lg) {
		font-size: $font-size-h1;
	}

}
h2 {
	font-size: $font-size-m-h2;
	font-family: $avenir-next-rounded-demi;

	@include respond-to(sm) {
		font-size: $font-size-sm-h2;
	}
	@include respond-to(md) {
		font-size: $font-size-md-h2;
	}
	@include respond-to(lg) {
		font-size: $font-size-h2;
	}
}
h3 {
	font-size: $font-size-m-h3;
	font-family: $avenir-next-rounded-demi;

	@include respond-to(md) {
		font-size: $font-size-h3;
	}
}
h4 {
	font-size: $font-size-m-h4;
	font-family: $avenir-next-rounded-demi;

	@include respond-to(md) {
		font-size: $font-size-h4;
	}
}
h5 {
	font-size: $font-size-m-h5;
	font-family: $avenir-next-rounded-demi;

	@include respond-to(md) {
		font-size: $font-size-h5;
	}
}
h6 {
	font-size: $font-size-m-h6;
	font-family: $avenir-next-rounded-demi;

	@include respond-to(md) {
		font-size: $font-size-h6;
	}
}

/*Headers END*/