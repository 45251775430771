//Buttons
.btn {
	border: color(honda-blue) solid 2px;
	border-radius: 20px;
	font-family: $avenir-next-bold;
	font-size: $font-size-m-btn;
	height: 36px;
	&:focus {outline:0 !important;}
	@include respond-to(sm) {
		font-size: $font-size-sm-btn;
	}
	@include respond-to(md) {
		font-size: $font-size-btn;
	}
}

.btn-danger {
	background-color: rgb(228, 37, 37);
	border: 1px solid rgb(228, 37, 37);
}

.btn-blue, .btn-primary {
	color: #fff;
	background-color: color(honda-blue);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s  ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	&:hover {
		background-color: #000;
		border: #000 solid 2px;
	}

	&:active {
		background-color: #000;
		border: #000 solid 2px;
	}
}

.btn-secondary {
	color: color(honda-blue);
	background-color: #fff;
	outline:0 !important;
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s  ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	&:hover {
		background-color: color(honda-blue) !important;
		color: #fff;
		outline:0 !important;
	}

	&:focus {
		outline:0 !important;
		background-color: #fff;
	}

	&:visited {
		outline:0 !important;
		background-color: #fff;
		color: color(honda-blue);
	}

	&:active {
		background-color: #fff;
		border: #666666 solid 2px;
		color: #666666;
		outline:0 !important;
	}

}

.btn-tertiary {
	color: color(honda-blue);
	background-color: #fff;
	outline:0 !important;
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s  ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;

	&:hover {
		/*@include respond-to(sm) {
		    background-color: color(honda-blue) !important;
		    color: #fff;
            outline:0 !important;
	    }*/
	    @include respond-to(md) {
		    background-color: #fff !important;
		    color: color(honda-blue) !important; 
            outline:0 !important;
	    }
		 background-color: color(honda-blue) !important;
		    color: #fff;
            outline:0 !important;
	}

	&:focus {
		outline:0 !important;
		background-color: #fff;
		color: color(honda-blue);
	}

	&:visited {
		outline:0 !important;
		background-color: #fff;
		color: color(honda-blue);
	}

	&:active {
		background-color: #fff;
		border: #666666 solid 2px;
		color: #666666;
		outline:0 !important;
	}

}

.btn-icon span {
	background-image: url(/images/global/btn-icon-sprite.png);
	width: $font-size-btn;
	height: 14px;
	position: relative;
	top: 2px;
	display: inline-block;
	margin-right: 10px;	

}

.btn-secondary:hover .btn-icon {
	background-position: 0px 30px;	
}

.btn-secondary:active .btn-icon {
	background-position: 0px 30px;
}

.btn-secondary:focus .btn-icon {
	background-position: 0 14px;
}

.btn-secondary:visited .btn-icon {
	margin-top: 20px;
	background-position: 0 0px;
}

.btn-link {
	color: color(honda-blue);
	font-family: $avenir-next-bold;
	font-size: $font-size-btn;
	height: 36px;
	text-decoration: underline;
	border: none;
}

.icon-only {
	background-color: color(honda-blue);
	width:50px;
	height: 50px;
	border-radius: 50%;
	background-repeat: no-repeat;
    background-position: 13px 14px;
	background-image: url(/images/global/map-icon.png);
}

/*New bttns with icons*/
.btn-icon-only {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-repeat: no-repeat;
/*bttn with map icon*/
	&.btn-map {
		background-color: color(honda-blue);
		background-position: 13px 14px;
		background-image: url(/images/global/map-icon.png);
	}
/*bttn with left arrow icon	*/
	&.btn-left-arrow {
		background-color:#fff;
		background-position: 16px 14px;
		background-image: url(/images/global/back-arrow-icon.png);
	}
}
//Buttons END