﻿// HD
// This mixin will
// - set parent container display to table
// - set the height to included arg
// - set display of child-container to table-cell, set vertical-align to middle and height to 100%
// so that content is vertically centered
@mixin table-cell-vertical-align($height, $childContainer) {
    display: table;
    height: $height;
    width: 100%;

    #{$childContainer} {
        display: table-cell;
        vertical-align: middle;
        height: 100%;
    }
}
// animations
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards;
    /* this prevents the animation from restarting */
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards;
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards;

}
@include keyframes(slidedown) {
    0% {
        top:70px;
    }
    100% {
        top: 70px;
    }
}
