input[type="checkbox"] {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  border: 1.5px #a8a8a8 solid;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  margin: 0;
  outline: none !important;
  width: 24px;

  -moz-appearance: none;
  -webkit-appearance: none;

  &:not(:checked) {
    box-shadow: 0px 1px 0px 0px rgba(black, 0.6) inset;
  }

  &:checked {
    background-color: rgb(5, 108, 165);
    background-image: url(/images/icons/check.png);
  }
}

input[type="checkbox"]::-ms-check {
  color: transparent;
  background: none;
  border: 1.5px #a8a8a8 solid;
  border-radius: 2px;
  box-sizing: border-box;
}

input[type="checkbox"]:not(:checked)::-ms-check {
  box-shadow: 0px 1px 0px 0px rgba(black, 0.6) inset;
}

:-moz-any(input[type="checkbox"]) {
  border-width: 2px !important;
}
