// Mixins
@import '_variables'; // font size here
@import '../mixins/_breakpoints';
@import '../mixins/_alignment'; // Alignment functions
@import '../mixins/_gradients'; // Gradient functions


// Partials
// @import '_temp'; //body::before "breakpoints"
@import '_reset';
@import '_base';
@import "/fonts/fonts.css";  
@import '_fonts';
@import '_carousel';
@import '_colors';
@import '_copy'; // copy/Form fields
@import '_checkbox';
@import '_buttons';
@import '_nav';
@import '_footer';
@import '_disclaimers'; // disclaimers
@import '_header';
@import '_forms';
@import '_misc'; // "hr" line
@import '_geolocation'; // geolocation
@import '_errors'; // geolocation
@import '_modal';

// Vendor
@import '../vendor/_jquery-ui-labeledslider';