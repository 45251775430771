// Colors

// hd:
// Keep all colors in this sass map
// more organized than variables
// can have variables as a convenience method if you want
// use color(blue) to get your color

$colors: (
  black: #000,
  white: #fff,
  blue: #2198dc,
  honda-blue: #007cc3,
  dark-blue: #0074B3,
  light-blue: #2198dc,
  dark-grey: #666,
  light-grey: #e8e8e8,
  error: #ff0000
);

@function color($color) {
    @return map-get($colors , $color)
}