/*form*/
.form-group {
	position: relative;
}
.form-control {
	height: 30px;
	font-size: $font-size-m-form;
	background-color: #f2f2f2 !important;
	border-radius: 20px;
	font-family: $avenir-next-regular;

	&:focus {
		border: color(honda-blue) solid 1px;
	}
	@include respond-to(md) {
		height: 44px;
		font-size: $font-size-form;
	}
}
.form-error {
	color: color(error) !important;
	border: color(error) solid 1px !important;
}
input[type=radio].checkbox {
	position: absolute;
	z-index: -1000;
	left: -1000px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
input[type=radio].checkbox + label.css-label {

	/*padding-left: 41px;*/
	height: 20px;
	width: 20px;
	display: inline-block;
	line-height: 36px;
	background-repeat: no-repeat;
	background-position: 0 0;
	vertical-align: middle;
	cursor: pointer;
	padding: 0;
	margin: 0;

	@include respond-to(md) {
		width: 30px;
		height: 30px;
	}
}
input[type=radio].checkbox:checked + label.css-label {
	background-position: 0 -20px;

	@include respond-to(md) {
		background-position: 0 -29px;
	}
}
label.css-label {
	background-image: url(/images/global/radio.png);
	background-size: cover;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.search-box {
	padding-left: 30px;
	padding-right: 20px;

	@include respond-to(md) {
		padding-left: 90px;
	}
}
.search-icon {
	position: absolute;
	display: block;
	top: 27px;
	left: 18px;
	width: 9px;
	height: 9px;
	background-image: url(/images/global/search-icon.png);
	background-size: cover;

	@include respond-to(md) {
		width: 16px;
		height: 16px;
		top: 32px;
		left: 65px;
	}
}
.clear-form {
	position: absolute;
	top: 27px;
	right: 10px;
	width: 9px;
	height: 9px;
	background-image: url(/images/global/clear.png);
	cursor: pointer;
	background-size: cover;

	@include respond-to(md) {
		width: 16px;
		height: 16px;
		top: 32px;
		right: 15px
	}
}

label.container-radio {
	margin: 10px 0;
	padding-left: 30px;

	input[type="radio"] {
		width: 20px;
		height: 20px;
		margin-top: -2px;
		margin-left: -30px;
	}

	input[type="radio"]:checked {
		font-weight: bold;
	}
}

.radio-offer:checked,
.radio-offer {
	position: absolute;
	opacity: 0;
  cursor: pointer;
}

.radio-offer:checked + label,
.radio-offer:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
		width: 100%;
		margin: 5px 0;

	span {
		font-weight: bold;
		color: #007cc3;
		font-size: 20px;
	}
}

.radio-offer:checked + label:before,
.radio-offer:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #007cc3;
    border-radius: 100%;
    background: #fff;
}

.radio-offer:checked + label:after,
.radio-offer:not(:checked) + label:after {
	content: '';
	width: 12px;
	height: 12px;
	background: #007cc3;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.radio-offer:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.radio-offer:checked + label {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	font-weight: normal;
	font-family: $avenir-next-bold;
}

/*form END*/