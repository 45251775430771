@import "partials/_main";

.error-pages {
    .row {
        padding: 12px;
    }
    .section {
        float: none;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        border-radius: 10px;
        background-color: #fff;
        padding-top: 220px;
        padding-bottom: 220px;
    }
}