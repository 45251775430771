/*NAVBAR*/
.navbar-hidden {
  @include respond-to(xs) {
    transform: translateY(-50px);
  }
}

.skip-nav {
  background: white;
  color: black;
  display: block;
  font-family: $avenir-next-bold;
  font-size: 12px;
  left: 15px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform 0.2s ease-out;
  transform: translateY(-100px);
  top: -40px;
  visibility: visible !important;
  z-index: 10000;

  &:focus,
  &:active {
    top:15px;
    height: auto;
    opacity: 1;
    outline: -webkit-focus-ring-color auto !important;
    outline-offset: 0.3rem !important;
    padding: 10px;
    text-decoration: none;
    transform: translateY(18px);
    width: 150px;
  }
}

.navbar {
  background-color: #fff;
  border: none;
  height: 4.375rem;
  margin: 0 auto;
  max-width: 1440px;
  padding-top: .4375rem;
  width: 100%;

  @include respond-to(xs) {
    background-color: color(honda-blue);
    transition: transform .5s;
  }

  @include respond-to(sm) {
    background-color: color(honda-blue);
  }

  @media #{$lg-breakpoint} {
    padding-top: 0;
    background-color: #fff;
  }

  .nav-links-center {
    font-size: 16px;
    font-family: $avenir-next-bold;
    z-index: 1;

    /*move 2nd menu to top position*/
    @include respond-to(md) {
      margin-top: 40px;
      margin-bottom: 0;
    }

    @include respond-to(xl) {
      margin-top: 30px;
    }

    .nav>li>a {
      @include respond-to(md) {
        padding-top: 0;
        padding-bottom: 0;
        padding-inline: .625rem;
      }
    }

    ul {
      @include respond-to(xl) {
        float: left !important;
        padding-left: 20%;
      }
    }
  }

  .nav-links-right {
    font-family: $avenir-next-light;
    font-size: 14px;
    overflow: visible;
    z-index: 2;

    /*move 2nd menu to top position*/
    @include respond-to(md) {
      position: absolute;
      right: 0;
      margin-top: 10px;
      margin-right: 15px;
    }

    @include respond-to(xl) {
      margin-top: 29px;
    }

    .nav>li>a, .nav>li>p {
      @include respond-to(md) {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .full-width {
    @include respond-to(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/*navbar END*/
.navbar-fixed-top {
  z-index: 3;
}

.navbar-default {
  .navbar-nav>li>a {
    color: #000;

    &:focus {
      outline: 2px solid #000;
      outline-offset: 3px;
    }
  }

  @include respond-to(xs) {
    background-color: color(honda-blue);
  }
}

.navbar-brand {
  left: 0;
  position: absolute;
  padding: 14px 0;
  max-width: 95px;
  margin: auto !important;
  z-index: 5;
  right: 0;
  width: 95px;

  @include respond-to(sm) {
    padding: 18px 0;
    max-width: 118px;

  }

  @media #{$lg-breakpoint} {
    width: auto;
    left: 15px;
    background: color(honda-blue);
    height: 70px;
    max-width: 237px;
    padding: 0;
    margin-left: -15px !important;

    &:focus,
    &:hover{
      background: color(honda-blue) !important;
    }
  }

  .logo-wrapper{
    width: 118px;
    height: 30px;
    position: relative;
    right: 15px;

    @media #{$lg-breakpoint} {
      width: 200px;
      height: 42px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }
  }

  .logo{
    background-image: url(/images/global/HCPV_logo.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;

    @include respond-to(md) {
      height: 100%;
    }
  }
}

.nav>li {
  @include respond-to(xs) {
    height: 50px;
    border-bottom: solid 1px #e8e8e8;
  }
}

.nav >li >a {
  padding-top: 25px;
  padding-bottom: 25px;

  &:hover {
    color: color(dark-grey) !important;
  }
}

.navbar-toggle {
  float: left;
  padding: 10px 0 0 0;
  margin: 4px 0;
  border: none;

  @include respond-to(sm) {
    margin: 8px 0;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto !important;
    outline-offset: 0.3rem !important;
  }
}

ul.navbar-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s  ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 1;

  &.active {
    -webkit-transform: translate(0, 30px);
    -moz-transform: translate(0, 30px);
    -o-transform: translate(0, 30px);
    -ms-transform: translate(0, 30px);
    transform: translate(0, 30px);
    opacity: 0;
  }
}

.navbar-nav {
  @include respond-to(md) {
    float: right !important;
  }
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
  border-radius: 10px;
  height: 4px;
  width: 25px;
}

.nav-zip-input {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  background: color(light-blue);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  -webkit-transform: translate(0, -50px);
  -moz-transform: translate(0, -50px);
  -o-transform: translate(0, -50px);
  -ms-transform: translate(0, -50px);
  transform: translate(0, -50px);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s  ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 0;
  z-index: 0;

  &.active {
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
    opacity: 1;
    z-index: 20;
  }

  @media #{$lg-breakpoint} {
    width: 290px;
    height: 35px;
    background: #fff;
    top: 5px;
    bottom: 0;
    right: 10px;
    left: inherit;
  }

  @include respond-to(xl) {
    height: 35px;
    top: 25px;
    -webkit-transform: translate(0, -55px);
    -moz-transform: translate(0, -55px);
    -o-transform: translate(0, -55px);
    -ms-transform: translate(0, -55px);
    transform: translate(0, -55px);
  }

  .search-box {
    box-sizing: border-box!important;
    margin-top: 9px;
    padding: 0 0 0 30px;
    border: 1px solid #f2f2f2;
    z-index: 2;
    position: relative;

    @include respond-to(xs) {
      font-size: 16px;
    }

    @include respond-to(sm) {
      font-size: 12px;
      height: 30px;
      margin-top: 15px;
    }

    @media #{$lg-breakpoint} {
      margin-top: 0;
      height: 30px;
    }
  }

  .geo-go-search {
    position: absolute;
    display: block;
    top: 10px;
    right: 20px;
    width: 27px;
    height: 27px;
    background-image: url(/images/global/geolocation/go-search-geo.png);
    background-size: cover;
    cursor: pointer;
    margin-bottom: 10px;
    z-index: 2;

    @include respond-to(sm) {
      top: 17px;
    }

    @media #{$lg-breakpoint} {
      top: 5px;
      width: 20px;
      height: 20px;
    }
  }

  .close-nav-zip-input {
    background-color: transparent;
    border: 0 none;
    z-index: 2;
    position: absolute;
    top: 16px;
    left: 25px;
    width: 15px;
    height: 15px;
    background-image: url(/images/global/clear.png);
    cursor: pointer;
    background-size: cover;

    &:focus {
      outline: -webkit-focus-ring-color auto !important;
      outline-offset: 0.3rem !important;
    }

    @include respond-to(sm) {
      top: 23px;
    }

    @media #{$lg-breakpoint} {
      width: 13px;
      height: 13px;
      top: 8px;
      left: 25px;
    }
  }
}

/*nav-zip-input END*/
.geo-input-error-wrapper {
  background-color: #fff;
  margin-top: -15px;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  height: 0;
  overflow: hidden;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s  ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 0;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -o-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #f2f2f2;

  &.active {
    height: 100%;
    opacity: 1;
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }

  .geo-input-error-steps p {
    font-size: $font-size-m-form;
    margin: 10px 0;
    font-family: $avenir-next-regular;
  }

  .btn {
    width: 240px;
    padding: 0;
    height: 28px;

    @include respond-to(sm) {
      width: 275px;
    }

    @include respond-to(md) {
      width: 100%;
      font-size: 13px;
    }
  }

  .btn-icon span {
    background-image: url(/images/global/geolocation/geo-icon.png);
    width: 13.5px;
    height: 13.5px;
    background-size: cover;
    top: 2px;
  }

  .geo-input-error-title {
    color: color(error);
  }
}

.navbar-header {
  .location {
    align-items: center;
    display: flex;
    width: 45px;
    height: 26px;
    background: transparent url(/images/global/geolocation/location-mobile-icon.png) no-repeat 0 0;
    border: 0 none;
    float: right;
    margin: 10px 0px;
    padding: 0;
    font-family: $avenir-next-light;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    background-position: right center;
    background-size: 12px;
    text-align: left;

    &:focus {
      outline: -webkit-focus-ring-color auto !important;
      outline-offset: 0.3rem !important;
    }

    @include respond-to(sm) {
      width: 65px;
      margin: 15px 0px;
      background-size: 15px;
    }

    > i {
      align-items: center;
      display: inline-flex;
      font-style: normal;
      margin: 0 5px 0 0;
      font-size: 12px;

      @include respond-to(sm) {
        margin: 8px 0 0 5px;
        font-size: 16px;
      }
    }
  }

  @media #{$lg-breakpoint} {
    width: 25%;
  }
}

/*navbar-header END*/
.navbar-collapse {
  background-color: #fff;
}

.navbar-nav {
  .form-group {
    .search-box {
      height: 35px;
    }

    .geo-go-search {
      position: absolute;
      display: block;
      top: 6px;
      right: 5px;
      width: 23px;
      height: 23px;
      background-image: url(/images/global/geolocation/go-search-geo.png);
      background-size: cover;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }

  button.location {
    align-items: center;
    background-color: transparent;
    border: 0 none;
    display: flex;
    padding-right: 15px;
    z-index: 2;
    cursor: pointer;

    &:focus {
      outline: -webkit-focus-ring-color auto !important;
      outline-offset: 0.3rem !important;
    }

    > i {
      font-style: normal;

      @include respond-to(md) {
        margin-right: 5px;
      }
    }

    @include respond-to(md) {
      margin-top: 0px;
    }
  }
}

/*active & focus*/
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #0074b3;
  background-color: #fff;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
  @include respond-to(lg) {
    margin-left: 15px;
  }
}

/*for mobile menu*/
body {
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

/* Toggle Styles */
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 0;

  @include respond-to("tablet-") {
    padding-left: 60%;
  }
}

#sidebar-wrapper {
  background: #fff;
  height: 100%;
  left: 250px;
  margin-left: -250px;
  overflow-y: auto;
  position: fixed;
  top: 4.375rem;
  width: 0;
  z-index: 1000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 100%;

  @include respond-to(lg) {
    width: 0%;
  }
}

#page-content-wrapper {
  width: 100%;
  position: relative;
  background-color: #e8e8e8;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}
/* Toggle Styles END */

/* Sidebar Styles */
.sidebar-nav-primary {
  top: 0;
  width: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    text-indent: 20px;
    line-height: 47px;
    height: 45px;
    border-bottom: 1px #e8e8e8 solid;
    font-family: $avenir-next-demi;
    font-size: $font-size-m-sidebar-primary;

    a {
      display: block;
      text-decoration: none;
      color: #000;

      img {
        margin-left: 20px;
      }
    }
  }

  .active a {
    color: color(dark-blue);
  }
}

.sidebar-nav-secondary {
  top: 0;
  width: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    text-indent: 20px;
    line-height: 47px;
    height: 50px;
    border-bottom: 1px #e8e8e8 solid;
    font-family: $avenir-next-light;
    font-size: $font-size-m-sidebar-secondary;

    a, p {
      display: block;
      text-decoration: none;
      color: #000;
    }

    button.location {
      align-items: center;
      background-color: transparent;
      background-position: right center;
      display: inline-flex;
      border: 0 none;
      padding: 0;

      &:focus {
        outline: -webkit-focus-ring-color auto !important;
        outline-offset: 0.3rem !important;
      }

      > i {
        align-items: center;
        color: #000;
        display: inline-flex;
        font-style: normal;
        text-decoration: none;
        margin-right: 5px;
      }
    }
  }

  .active a {
    color: color(honda-blue);
  }
}

/*Bootstrap Mobile menu override*/
@media (max-width:1023px) {
  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-text {
    float: none;
    margin: 15px 0;
  }

  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .collapsing {
    overflow: hidden !important;
  }
}
/* Sidebar Styles END */

/*Mobile menu animation to X*/
.navbar-toggle .icon-bar:nth-of-type(2) {
  top: 1px;
  transform: rotate(0);
  -webkit-transform: rotate(0);
}

.navbar-toggle .icon-bar:nth-of-type(3) {
  top: 2px;
  transform: rotate(0);
  -webkit-transform: rotate(0);
}

.navbar-toggle {
  .icon-bar {
    position: relative;
    transition: all 500ms ease-in-out;
  }

  &:focus,
  &:focus-within {

    &,
    &:hover {
      outline: -webkit-focus-ring-color auto !important;
      outline-offset: 0.3rem !important;
    }

    .icon-bar {
      outline: none !important;
    }
  }
}

.navbar-toggle.active .icon-bar:nth-of-type(1) {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform-origin: 20% 28%;
  -webkit-transform-origin: 20% 28%;
}

.navbar-toggle.active .icon-bar:nth-of-type(2) {
  background-color: transparent;
}

.navbar-toggle.active .icon-bar:nth-of-type(3) {
  top: -8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
}
/*Mobile menu animation to X END*/
/*NAVBAR END*/
