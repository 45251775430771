$breakpoints: (
  mobile: 0px,
  tablet: 768px,
  desktopsm: 992px,
  desktopmd: 1200px,
  desktoplg: 1440px
);

$lg-breakpoint: 'only screen and (min-width: 64em)';

@mixin respond-to($breakpoint) {
  @if $breakpoint == "xs" {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint == "sm" {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $breakpoint == "md" {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $breakpoint == "lg" {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else if $breakpoint == "xl" {
    @media (min-width: 1440px) {
      @content;
    }
  }

  // below are more clear and expressive variable names and breakpoints
  // ---------------------------------------------------------
  // mobile
  @else if $breakpoint == "mobile" {
    @media (min-width: map-get($breakpoints, mobile)) and (max-width: map-get($breakpoints, tablet) - 1px) {
      @content;
    }
  }

  // mobile+
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('mobile+')
  @else if $breakpoint == "mobile+" {
    @media (min-width: map-get($breakpoints, mobile)) {
      @content;
    }
  }

  // ---------------------------------------------------------
  // tablet-
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('tablet-')
  @else if $breakpoint == "tablet-" {
    @media (max-width: map-get($breakpoints, desktopsm) - 1px) {
      @content;
    }
  }

  // tablet
  @else if $breakpoint == "tablet" {
    @media (min-width: map-get($breakpoints, tablet)) and (max-width: map-get($breakpoints, desktopsm) - 1px) {
      @content;
    }
  }

  // tablet+
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('tablet+')
  @else if $breakpoint == "tablet+" {
    @media (min-width: map-get($breakpoints, tablet)) {
      @content;
    }
  }

  // ---------------------------------------------------------
  // desktopsm-
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('desktopsm-')
  @else if $breakpoint == "desktopsm-" {
    @media (max-width: map-get($breakpoints, desktopmd) - 1px) {
      @content;
    }
  }

  // desktopsm
  @else if $breakpoint == "desktopsm" {
    @media (min-width: map-get($breakpoints, desktopsm)) and (max-width: map-get($breakpoints, desktopmd) - 1px) {
      @content;
    }
  }

  // desktopsm+
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('desktopsm+')
  @else if $breakpoint == "desktopsm+" {
    @media (min-width: map-get($breakpoints, desktopsm)) {
      @content;
    }
  }

  // ---------------------------------------------------------
  // desktopmd-
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('desktopmd-')
  @else if $breakpoint == "desktopmd-" {
    @media (max-width: map-get($breakpoints, desktoplg) - 1px) {
      @content;
    }
  }

  // desktopmd
  @else if $breakpoint == "desktopmd" {
    @media (min-width: map-get($breakpoints, desktopmd)) and (max-width: map-get($breakpoints, desktoplg) - 1px) {
      @content;
    }
  }

  // desktopmd+
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('desktopmd+')
  @else if $breakpoint == "desktopmd+" {
    @media (min-width: map-get($breakpoints, desktopmd)) {
      @content;
    }
  }

  // ---------------------------------------------------------
  // desktoplg-
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('desktoplg-')
  @else if $breakpoint == "desktoplg-" {
    @media (max-width: map-get($breakpoints, desktoplg) - 1px) {
      @content;
    }
  }

  // desktoplg+
  // for usage, enclose in quotes with string contains symbols
  // so: respond-to('desktoplg+')
  @else if $breakpoint == "desktoplg+" {
    @media (min-width: map-get($breakpoints, desktoplg)) {
      @content;
    }
  }
}//end mixin respond-to

// testing unselectable
@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}