.scroll-snap-page-container {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
	-ms-overflow-style: none; // hide scrollbar on IE
	overflow-x: scroll;
	overscroll-behavior-x: none;
	-ms-scroll-chaining: none;
	scroll-behavior: smooth; // for browsers with support
	scrollbar-color: transparent transparent; // hide scrollbar on Firefox
	scrollbar-width: thin; // Firefox
	scroll-snap-stop: always;
	scroll-snap-type: x mandatory;

	&::-webkit-scrollbar {
		background-color: transparent !important;
		display: none;
		width: 14px;
	}

	&::-webkit-resizer,
	&::-webkit-scrollbar-button,
	&::-webkit-scrollbar-corner {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent !important;
		border: 4px solid transparent !important;
		border-radius: 23px;
		transition: background-color 0.3s;
	}

	&::-webkit-scrollbar-track {
		background: transparent !important;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent !important;
		margin: 0 20px;
	}

  @media (hover: hover) and (pointer: fine) {
		&.has-overflow {
      padding-bottom: 26px;

			.controls:hover {
				opacity: 1;
				visibility: visible;
			}
		}
	}

  .scroll-snap-page {
		scroll-snap-align: start;
	}

  // While rendering the scroll snap items, we can scroll without smooth for an immediate jump
	&.rendering {
		scroll-behavior: auto; // for browsers with support

		.scroll-snap-page {
			opacity: 0;
		}
	}

	@include respond-to(md) {
		&::-webkit-scrollbar-track-piece {
			margin: 0 28px;
		}

    &.has-overflow {
      -ms-overflow-style: initial; // show scrollbar on IE
      scrollbar-color: #909090 transparent; // show scrollbar on Firefox

      &::-webkit-scrollbar {
        display: initial; // show scrollbar on Webkit
      }

      &::-webkit-scrollbar-thumb {
        background-color: #909090;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #232323;
      }
		}

		@media (hover: none) {
			&.has-overflow {
				-ms-overflow-style: initial; // show scrollbar on IE
				padding-bottom: 22px;
				scrollbar-color: #909090 transparent; // show scrollbar on Firefox

				&::-webkit-scrollbar {
					display: initial; // show scrollbar on Webkit
				}

				&::-webkit-scrollbar-thumb {
					background-color: #909090;
				}
			}
		}
	}

	@include respond-to(lg) {
		&::-webkit-scrollbar-track-piece {
			margin: 0 53px;
		}

		&::-webkit-scrollbar {
			display: initial; // show scrollbar on Webkit, prevents card resize on hover
		}

		&.has-overflow {
			@media (hover: hover) and (pointer: fine) {
				padding-bottom: 13px;
			}

			// Firefox only
			/* stylelint-disable-next-line function-url-quotes */
			@-moz-document url-prefix() {
				padding-bottom: 23px;
			}
		}
	}

  .scroll-snap-page {
    background-image: none;
    flex: 0 0 296px;

    @include respond-to(sm) {
      flex-basis: 345px;
    }

    @include respond-to(md) {
      flex-basis: 454px;
    }

    &.first {
      margin-left: 0;
      padding-left: 0;
    }

    &.last {
      padding-right: 0;
    }
  }
}

.scroll-snap-page-controls {

  @media (max-width:400px) {
    display: none;
  }

  button {
    color: #fff;
    background: rgba(0,0,0,0.5) none;
    border: 0 none;
    border-radius: 50%;
    bottom: 50%;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    width: 40px;
    z-index: 2;

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &[disabled="true"] {
      opacity: 0;
    }

    &:focus,
    &:focus:hover {
      outline: 0 none !important;
    }
  }
}

.page-indicator {
  bottom: 2px;
  left: 50%;
  max-width: 240px;
  overflow: visible;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;

  &:has(.past-threshold) {
    max-width: 120px;
  }

  @media (min-width:400px) {
    display: none;
  }

  .pages {
    backface-visibility: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    // 5 is max visible before sizing effect
    &:has(> .page:nth-of-type(5)) {
      justify-content: flex-start;
      transform: none;
      transition: transform 0.3s;

      &.past-threshold {
        transform: translate3d(-20px, 0, 0);
      }
    }

    &:has(> .page:nth-of-type(6)) {
      .page {
        transition: all 0.3s ease;

        .indicator {
          transition: all 0.3s ease;
        }
      }
    }

    .page {
      align-items: center;
      background-image: none;
      display: inline-flex;
      margin: 0 !important;
      padding: 0 0 0 20px !important;
      transform: scale(1);

      &:first-of-type {
        padding-left: 0 !important;
      }

      .indicator {
        cursor: default; // poiner
      }

      &.current {
        .indicator {
          background-color: #fff;
          // cursor: default;
          opacity: 1;
        }
      }

      &.hidden {
        padding-left: 0;
        transform: scale(0);

        .indicator {
          opacity: 0;
          width: 0;
        }
      }

      &.medium {
        padding-left: #{20px * 0.85};
        transform: scale(0.85);
      }

      &.small {
        padding-left: #{20px * 0.5};
        transform: scale(0.5);
      }

      &.tiny {
        padding-left: #{20px * 0.4};
        transform: scale(0.4);

        .indicator {
          opacity: 0.5;
        }
      }

      &.micro {
        padding-left: #{20px * 0.3};
        transform: scale(0.3);

        .indicator {
          opacity: 0.4;
        }
      }

      .indicator {
        background-color: #000;
        border-radius: 50%;
        display: block;
        height: 20px;
        opacity: 0.3;
        overflow: hidden;
        text-indent: -1000px;
        transition: background-color 0.2s ease, opacity 0.2s ease;
        width: 20px;

        // &:not(.current):hover {
        //   background-color: #fff;
        //   opacity: 1;
        // }
      }
    }
  }
}
