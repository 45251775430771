﻿// gradients

// HD
// This blue gradient is used on the following pages
// offers/finance page
@mixin blue-gradient-1() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6fb5df+0,2c93ce+50,7bbbe3+100 */
  background: #6fb5df; /* Old browsers */
  background: -moz-linear-gradient(left, #6fb5df 0%, #2c93ce 50%, #7bbbe3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #6fb5df 0%, #2c93ce 50%, #7bbbe3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #6fb5df 0%, #2c93ce 50%, #7bbbe3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6fb5df', endColorstr='#7bbbe3', GradientType=1); /* IE6-9 */
}

// HD
// This blue gradient is used on the following pages
// offers/finance page
@mixin blue-gradient-2(){
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#009dec+0,007dc4+50,0094e2+100 */
  background: #009dec; /* Old browsers */
  background: -moz-linear-gradient(left,  #009dec 0%, #007dc4 50%, #0094e2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #009dec 0%,#007dc4 50%,#0094e2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #009dec 0%,#007dc4 50%,#0094e2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009dec', endColorstr='#0094e2',GradientType=1 ); /* IE6-9 */

}



@mixin blue-gradient-3(){
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00b3fe+1,0085ce+100 */
  background: #00b3fe; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #00b3fe 1%, #0085ce 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #00b3fe 1%,#0085ce 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #00b3fe 1%,#0085ce 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b3fe', endColorstr='#0085ce',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin blue-gradient-4(){
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00b3fe+1,0085ce+100 */
  background: #439cd0; /* Old browsers */
  background: -moz-linear-gradient(-180deg, #439cd0 1%, #007cc3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-180deg, #439cd0 1%,#007cc3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-180deg, #439cd0 1%,#007cc3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#439cd0', endColorstr='#007cc3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin background-skeleton() {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 900px;
}