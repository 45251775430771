/*
    below code applies to...
    "contact dealer" modal in VDP,
    "calculate monthly payment modal in VDP",
    "create alert" modal in inventory filters
*/

.modal-box {
    .contact-box-btn-x,
    .alert-box-btn-x {
        background-image: url(/images/global/geolocation/close-icon-geo.png);
        background-repeat: no-repeat;
        background-size: 12.5px;
        width: 12.5px;
        height: 12.5px;
        float: right;
        margin: 10px;
        cursor: pointer;
        -webkit-transition: ease 0.25s all;
        transition: ease 0.25s all;

        &:hover {
            -ms-transform: rotate(180deg);
            /* IE 9 */
            -webkit-transform: rotate(180deg);
            /* Safari */
            transform: rotate(180deg);
        }
    }

    .fixed-top {
        position: sticky;
        position: -webkit-sticky; // safari
        width: 100%;
        top: 0;
        background-color: #ccc;
        padding: 15px;
        z-index: 2;

        h3 {
            text-align: center;
        }

        &.transp-bg {
            background-color: transparent;
        }
    }

    .modal-honda-loader {
        img {
            position: fixed;
            margin: 0 auto;
            max-width: 100px;
            top: 45%;
            left: 0;
            right: 0;
            z-index: 1;
        }
    }

    .alert-form-btn-create-alert,
    .btn-dealer-send-email {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
    }
}
